import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import Work from '../components/Home/Work'
import { screenSizes, gutter, colors } from '../theme'
import HomeScreenshot from '../static/images/pages/home_base64'
import HomeScreenshotTablet from '../static/images/pages/home_tablet_base64'
import TextSliderBase from '../components/TextSlider'
import Section from '../components/Section'
import CircleTest from '../components/CircleTest'

const HeaderContainer = styled.div`
  max-width: 500px;
  width: 90%;
  display: none;
  position: relative;
  z-index: 21;
  flex-direction: row;
  align-items: flex-start;
  text-transform: uppercase;

  p {
    margin-bottom: 0;
    display: none;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    p {
      display: block;
    }
  }

  span {
    line-height: 15pt;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    display: flex;
  }
`

const SERVICES_SLIDES = [
  {
    title: 'The Geeks',
    body: (
      <span>
        Before all else we’re coders - problem solvers by nature, and nerds at
        heart who dig to tweak out on tech. We run a tight ship, manned by
        people who know how to run their own shit. This culture of ownership
        makes for a flat company, with emphasis on the team. It’s an environment
        that allows people to grow into their goals, and contribute to achieving
        the shared vision.
      </span>
    ),
  },
  {
    title: 'The Process',
    body: (
      <span>
        Every new project is individually assessed, and broken up into chewable
        chunks, set to milestones. Bespoke timelines, and our proven methodology
        keeps things on track, but factors in agility and exploration - keeping
        your budget in check.
      </span>
    ),
  },
  {
    title: "The Do's",
    body: (
      <span>
        We transform designs into clickable eye-candy, using UX and UI
        principles and best practice. Then our Geeks tap in, employing a
        combination of code and ingenuity to transform this outer layer into a
        high-performing, one-of-a-kind digital product. Every site, app or
        interface is built to achieve business results, and user satisfaction.
      </span>
    ),
  },
]

const TextSlider = styled(TextSliderBase)`
  padding: 0px ${gutter.mobile}px;

  @media (min-width: ${screenSizes.tablet}px) {
    padding: 100px ${gutter.tablet}px;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    padding: 100px ${gutter.desktop}px;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    padding: 100px ${gutter.desktopLarge}px;
  }
`

const ThreeModelContainer = styled.div`
  position: absolute;
  right: 0;
  max-width: 1920px;
  max-height: 1080px;
  z-index: 1;
  width: 100vw;
  height: 100vh;
`

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    const windowWidth = window.innerWidth

    this.setState({
      initialImage:
        windowWidth < screenSizes.desktop
          ? HomeScreenshotTablet
          : HomeScreenshot,
    })
  }
  render() {
    if (this.state.initialImage) {
      return (
        <Layout initialImage={this.state.initialImage}>
          <SEO title="Home" keywords={[`Teamgeek`]} />
          <ThreeModelContainer>
            <CircleTest />
          </ThreeModelContainer>
          <Hero
            extraPadding
            renderHead={() => (
              <HeaderContainer ref={hero => (this.hero = hero)} />
            )}
            titleList={['Word,', 'from the', 'mothership.']}
            arrowText="Right this way"
          />
          <Work />
          <Section bodyBackgroundColor={colors.primary}>
            <TextSlider slides={SERVICES_SLIDES} sectionTitle="Info" />
          </Section>
        </Layout>
      )
    }

    return <></>
  }
}

export default IndexPage
