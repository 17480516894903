import _ from 'lodash'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import Swiper from 'react-id-swiper'
import { navigate } from 'gatsby'

import { gutter, screenSizes, colors } from '../../theme'
import RollingText from '../RollingText'
import SectionIndicatorBase from '../SectionIndicator'
import withScrollAnimation from '../WithScrollAnimation'
import ThreeGlitch from '../ThreeGlitch'
import PropTypes from 'prop-types'

/* Work slider images */
import MenlynMaine from '../../static/images/mm_home.jpg'
import CodeMaven from '../../static/images/codemaven_home.jpg'
import BiB from '../../static/images/bib_home.jpg'
import Cultish from '../../static/images/cultish_home.jpg'
import Yoco from '../../static/images/yoco_home.jpg'
import Colab from '../../static/images/colab_home.jpg'
import FilmTalents from '../../static/images/filmtalents_home.jpg'

const TitleText = styled(RollingText)`
  position: relative;
  z-index: 3;
  transition: all ease 800ms;
  opacity: 0;
  top: 0;
  transform: translate(-50px, 0px);
  pointer-events: none;

  line-height: 18pt;
  font-size: 10pt;

  @media (min-width: ${screenSizes.tablet}px) {
    font-size: 22pt;
    line-height: 35pt;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    font-size: 20pt;
    line-height: 25pt;
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    font-size: 22pt;
    line-height: 27pt;
  }
`

const SlideNumberContainer = styled.div`
  display: flex;
  transition: all ease 1000ms;
  opacity: 0;
  transform: translateX(100%);
  grid-area: 1 / 2 / 1 / 3;
  justify-content: flex-end;

  p {
    line-height: 7pt;
    margin-bottom: 0;
  }

  @media (min-width: ${screenSizes.desktop}px) {
    justify-content: flex-end;
    grid-area: 1 / 8 / 1 / 12;
  }

  @media all and (orientation: portrait) and (min-width: ${screenSizes.tablet}px) {
    padding-right: calc(2.5vw + 15px);
  }
`

const SectionIndicator = styled(SectionIndicatorBase)`
  grid-row-start: 1;
  transition: all ease 1000ms;
  opacity: 0;
  transform: translateX(100%);
  line-height: 7pt;

  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 1 / 12 / 1 / 12;
    padding-left: 60px;
    color: ${colors.primaryText};
  }
`

const SwiperContainer = styled.div`
  width: 100%;
  transition: all ease 750ms;
  opacity: 0;
  grid-area: 2 / 1 / 2 / 3;

  .swiper-slide {
    overflow: hidden;
    height: 200px;
    width: 100%;
    opacity: 1;
    transition: all ease 2000ms;

    a {
      cursor: none;
    }

    &:last-child {
      width: 0;
    }
  }

  .swiper-slide-active {
    transition: all ease 500ms;

    ${props =>
      props.animating
        ? css``
        : css`
            &:hover {
              canvas {
                transform: scale(1.05);
              }
            }
          `};
  }

  @media all and (orientation: portrait) {
    margin-left: -2.5vw;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    .swiper-slide {
      margin-right: 60px;
      height: 300px;

      ${props => props.active && `margin-right: 15px;`}
    }
  }

  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 2 / 1 / 2 / 13;
    .swiper-slide {
      width: calc(100% - 250px - 200px - ${gutter.desktop}px);
      min-height: 300px;
      height: 55vh;

      &:last-child {
        width: calc(250px + ${gutter.desktopLarge}px + 200px);
      }
    }

    .swiper-wrapper {
      padding-left: calc(${gutter.desktop}px + 200px - 15px);
    }
  }

  @media (min-width: ${screenSizes.desktopLarge}px) {
    .swiper-slide {
      width: calc(90% - 250px - 200px - ${gutter.desktopLarge}px);
      height: 60vh;
      min-height: 500px;

      &:last-child {
        width: calc(250px + ${gutter.desktopLarge}px + 400px);
      }
    }

    .swiper-wrapper {
      padding-left: calc(${gutter.desktopLarge}px + 200px - 18px);
    }
  }

  @media (min-width: 1600px) {
    .swiper-slide {
      height: 80vh;
      min-height: 500px;
    }
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: all ease 1000ms;
  opacity: 0;
  pointer-events: none;

  p {
    margin-bottom: 0;
  }
  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 3 / 3 / 3 / 12;
  }
`

const GlitchImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  max-width: none;
  width: 100%;
  height: 100%;
  transition: top ease 100ms, transform ease 2000ms;

  canvas {
    transition: transform ease 3000ms;
  }
`

const Container = withScrollAnimation(
  styled.div`
    margin-top: 50px;
    min-height: 100vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 30px auto 30px 1fr;
    grid-row-gap: 15px;
    padding: 0 ${gutter.mobile}px;

    @media (min-width: ${screenSizes.tablet}px) {
      padding-left: ${gutter.tablet}px;
      padding-right: ${gutter.tablet}px;
    }

    @media (min-width: ${screenSizes.desktop}px) {
      min-height: 0;
      grid-row-gap: 0px;
      padding-left: 0;
      padding-right: 0;
      grid-template: 30px auto 30px / ${gutter.desktop}px 200px repeat(9, 1fr) 250px;
      grid-row-gap: 15px;
    }

    @media (min-width: ${screenSizes.desktopLarge}px) {
      grid-template:
        30px auto 30px / ${gutter.desktopLarge}px 200px repeat(9, 1fr)
        250px;
    }

    ${({ entered }) =>
      entered &&
      `
    ${Footer} {
      opacity: 1;
    }
    ${SwiperContainer} {
      opacity: 1;
    }

    ${TitleText} {
      transition-delay: 200ms;
      will-change: transform;
      transform: translate(0, 0);
      opacity: 1;
    }

    ${SlideNumberContainer}, ${SectionIndicator} {
      will-change: transform;
      opacity: 1;
      transform: translate(0, 0);
    }
  `}
  `,
  { enteredOffset: 0.1 }
)

const TitleContainer = styled.div`
  grid-area: 3 / 1 / 3 / 3;
  display: none;
  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 1 / 3 / 1 / 8;
    display: block;
  }
  @media (min-width: ${screenSizes.desktopLarge}px) {
    grid-area: 1 / 3 / 1 / 7;
  }
`

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${screenSizes.desktop}px) {
    grid-area: 3 / 12 / 3 / 13;
  }
`

const ClientName = styled(RollingText)`
  @media (min-width: ${screenSizes.desktop}px) {
    margin-top: -5px;
    grid-area: 3 / 3 / 3 / 7;
  }
`

const SLIDES = [
  {
    clientName: 'Yoco',
    image: Yoco,
    // to: '/case-studies/yoco',
  },
  {
    clientName: 'Colab',
    image: Colab,
    // to: '/case-studies/colab',
  },
  {
    clientName: 'Film Talents',
    image: FilmTalents,
    // to: '/case-studies/film-talents',
  },
  {
    clientName: 'Menlyn Maine',
    image: MenlynMaine,
    // to: '/case-studies/menlyn-maine',
  },
  {
    clientName: 'Cultish®',
    image: Cultish,
    // to: '/case-studies/cultish',
  },
  {
    clientName: 'Code Maven',
    image: CodeMaven,
    // to: '/case-studies/code-maven',
  },
  {
    clientName: 'BiB Audio Books',
    image: BiB,
    // to: '/case-studies/bib',
  },
]

class SwiperSlide extends React.PureComponent {
  static propTypes = {
    image: PropTypes.string.isRequired,
    active: PropTypes.bool,
  }

  static defaultProps = {
    active: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      hovering: false,
      showNoise: true,
    }

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.onImageLoaded = this.onImageLoaded.bind(this)
  }

  componentDidMount() {
    // this.container.addEventListener('mouseenter', this.onMouseEnter)
    // this.container.addEventListener('mouseleave', this.onMouseLeave)
  }

  componentWillUnmount() {
    // this.container.removeEventListener('mouseenter', this.onMouseLeave)
    // this.container.removeEventListener('mouseleave', this.onMouseLeave)
  }

  onMouseEnter() {
    this.setState({ hovering: true })
  }

  onMouseLeave() {
    this.setState({ hovering: false })
  }

  onImageLoaded() {
    this.setState({ showNoise: false })
  }

  render() {
    const { image, active } = this.props
    const { hovering, showNoise } = this.state
    return (
      <GlitchImageContainer
        ref={container => (this.container = container)}
        className="glitch-container"
      >
        <ThreeGlitch
          show
          image={image}
          goWild={false}
          glitchOffset={hovering ? 0 : 90}
          frequencyDivider={hovering ? 1 : 15}
          minXDistortion={0}
          maxXDistortion={hovering ? 0 : 2}
          minYDistortion={0}
          maxYDistortion={hovering ? 0 : 1}
          glitchAmountDivider={hovering ? 180 : 90}
          minAngle={hovering ? 0 : -Math.PI}
          maxAngle={hovering ? 0 : Math.PI}
          showNoise={showNoise}
          widthModifier={0.95}
          applyGlitch={active}
          onImageLoaded={this.onImageLoaded}
          forcePixelRatio={2}
        />
      </GlitchImageContainer>
    )
  }
}

class Work extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSlide: 0,
      animate: false,
    }

    this.swiper = null
    this.timeout = null
    this.onWindowResize = _.throttle(this.onWindowResize.bind(this), 50)
    this.onSliderMove = this.onSliderMove.bind(this)
    this.onSlideChangeEnd = this.onSlideChangeEnd.bind(this)
    this.updateSliderProgress = this.updateSliderProgress.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
    window.clearTimeout(this.timeout)

    if (this.swiper) {
      this.swiper.destroy()
    }
  }

  onSliderMove(event) {
    window.clearTimeout(this.timeout)
    if (event && (event.movementX || event.type === 'touchmove')) {
      this.setState({ animate: true })
    }
  }

  onWindowResize() {
    this.swiper.slideToClosest()
    window.setTimeout(() => {
      this.swiper.update()
    }, 500)
  }

  onSlideChangeEnd() {
    window.clearTimeout(this.timeout)
    let activeSlide = this.swiper.activeIndex
    if (activeSlide == SLIDES.length) {
      activeSlide = SLIDES.length - 1
      this.swiper.slideTo(activeSlide)
    }
    this.setState({ activeSlide }, () => {
      this.timeout = window.setTimeout(() => {
        this.setState({ animate: false })
      }, 300)
    })
  }

  onTouchStart() {
    window.clearTimeout(this.timeout)
    this.setState({ active: true })
  }

  onTouchEnd() {
    window.clearTimeout(this.timeout)
    this.setState({ active: false }, () => {
      this.timeout = window.setTimeout(() => {
        this.setState({ animate: false })
      }, 300)
    })
  }

  updateSliderProgress() {
    if (this.swiper && this.progressBar) {
      this.progressBar.updateProgress(this.swiper.progress * 100)
    }
  }

  render() {
    const { activeSlide, animate, active } = this.state

    const swiperParams = {
      containerClass: 'swiper-container swiper-container-work',
      touchStartPreventDefault: false,
      breakpointsInverse: true,
      slidesPerView: 'auto',
      preventInteractionOnTransition: true,
      watchSlidesVisibility: true,
      spaceBetween: 0,
      speed: 200,
      breakpoints: {
        [screenSizes.desktop]: {
          spaceBetween: 0,
          touchRatio: 3,
        },
        [screenSizes.tablet]: {
          spaceBetween: 0,
        },
      },
      on: {
        slideChangeTransitionStart: this.onSliderMove,
        slideChangeTransitionEnd: this.onSlideChangeEnd,
        sliderMove: event => {
          this.onSliderMove(event)
          this.updateSliderProgress()
        },
        transitionEnd: this.onSlideChangeEnd,
        progress: this.updateSliderProgress,
        touchStart: this.onTouchStart,
        touchEnd: this.onTouchEnd,
      },
    }

    return (
      <Container id="work">
        <TitleContainer>
          <TitleText text={'Latest Work'} />
        </TitleContainer>

        <SlideNumberContainer>
          <p>
            <RollingText
              numbersOnly
              animate={animate}
              text={(activeSlide + 1).toString()}
            />{' '}
            &mdash; {SLIDES.length}
          </p>
        </SlideNumberContainer>

        <SectionIndicator>Work</SectionIndicator>

        <SwiperContainer
          ref={swiperContainer => (this.swiperContainer = swiperContainer)}
          animating={animate}
          active={active}
          className="swiper-fast-snap"
        >
          <Swiper
            {...swiperParams}
            progress={this.state.progress}
            ref={node => {
              if (node) this.swiper = node.swiper
            }}
          >
            {SLIDES.map((slide, index) => {
              return (
                <div key={index}>
                  <a
                    onClick={e => {
                      e.preventDefault()
                      if (slide.to) {
                        navigate(slide.to)
                      }
                    }}
                    unselectable="true"
                    onDragStart={e => {
                      e.preventDefault
                      e.stopPropagation()
                      return false
                    }}
                  >
                    <SwiperSlide
                      active={index === activeSlide}
                      image={slide.image}
                    />
                  </a>
                </div>
              )
            })}
            <div />
          </Swiper>
        </SwiperContainer>

        <ClientName animate={animate} text={SLIDES[activeSlide].clientName} />
        <ProgressBarContainer>
          {/* <ProgressBar ref={ref => (this.progressBar = ref)} horizontal /> */}
        </ProgressBarContainer>
      </Container>
    )
  }
}

export default Work
