import React from 'react'
import * as THREE from 'three'
import EffectComposer, { RenderPass } from '@johh/three-effectcomposer'
import GlitchPass from '../passes/GlitchPass'
import PropTypes from 'prop-types'

export default class ThreeGlitch extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    image: PropTypes.string,
    goWild: PropTypes.bool,
    maintainAspectRatio: PropTypes.bool,
    style: PropTypes.object,
    glitchOffset: PropTypes.number,
    frequencyDivider: PropTypes.number,
    glitchAmountDivider: PropTypes.number,
    minXDistortion: PropTypes.number,
    maxXDistortion: PropTypes.number,
    minYDistortion: PropTypes.number,
    maxYDistortion: PropTypes.number,
    minAngle: PropTypes.number,
    maxAngle: PropTypes.number,
    showNoise: PropTypes.bool,
    applyGlitch: PropTypes.bool,
    onImageLoaded: PropTypes.func,
    widthModifier: PropTypes.number,
    animate: PropTypes.bool,
    glitchAnyways: PropTypes.bool,
    forcePixelRatio: PropTypes.number,
  }

  static defaultProps = {
    goWild: true,
    maintainAspectRatio: true,
    glitchOffset: 64,
    minXDistortion: 0,
    maxXDistortion: 1,
    minYDistortion: 0,
    maxYDistortion: 1,
    glitchAmountDivider: 90,
    minAngle: -Math.PI,
    maxAngle: Math.PI,
    showNoise: true,
    applyGlitch: true,
    widthModifier: 1,
    onImageLoaded: () => {},
    glitchAnyways: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      hide: false,
    }

    this.timeout = null
    this.onImageLoaded = this.onImageLoaded.bind(this)
    this.animate = this.animate.bind(this)
    this.onWidowResize = this.onWidowResize.bind(this)
    this.getImage = this.getImage.bind(this)

    this.imageWidth = 0
    this.imageHeight = 0
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show != this.props.show && !this.props.show) {
      this.setState({ hide: true })
    } else if (prevProps.show != this.props.show && this.props.show) {
      this.setState({ hide: false })
    }

    if (
      this.props.frequencyDivider !== prevProps.frequencyDivider ||
      this.props.minXDistortion !== prevProps.minXDistortion ||
      this.props.maxXDistortion !== prevProps.maxXDistortion ||
      this.props.minYDistortion !== prevProps.minYDistortion ||
      this.props.maxYDistortion !== prevProps.maxYDistortion ||
      this.props.glitchAmountDivider !== prevProps.glitchAmountDivider ||
      this.props.minAngle !== prevProps.minAngle ||
      this.props.maxAngle !== prevProps.maxAngle ||
      this.props.showNoise !== prevProps.showNoise ||
      this.props.applyGlitch !== prevProps.applyGlitch
    ) {
      this.glitchPass.dt_size = this.props.glitchOffset
      this.glitchPass.frequencyDivider = this.props.frequencyDivider
      this.glitchPass.minXDistortion = this.props.minXDistortion
      this.glitchPass.maxXDistortion = this.props.maxXDistortion
      this.glitchPass.minYDistortion = this.props.minYDistortion
      this.glitchPass.maxYDistortion = this.props.maxYDistortion
      this.glitchPass.glitchAmountDivider = this.props.glitchAmountDivider
      this.glitchPass.minAngle = this.props.minAngle
      this.glitchPass.maxAngle = this.props.maxAngle
      this.glitchPass.showNoise = this.props.showNoise
      this.glitchPass.applyGlitch = this.props.applyGlitch
    }

    if (this.props.glitchOffset !== prevProps.glitchOffset) {
      this.glitchPass.updateHeightmap(this.props.glitchOffset)
    }
  }

  async getImage() {
    try {
      const page = document.getElementsByTagName('html')[0]
      this.pageHeight = page.scrollHeight
      if (this.props.image) {
        this.img.src = this.props.image
      } else {
        this.props.onImageLoaded()
      }
    } catch (err) {
      console.error(err)
    }
  }

  onImageLoaded() {
    const width = this.root.clientWidth
    const height = this.root.clientHeight

    this.imageWidth = this.img.width
    this.imageHeight = this.img.height
    this.texture.needsUpdate = true
    // sprite.scale.set(width, that.pageHeight)
    if (this.props.maintainAspectRatio) {
      this.sprite.scale.set(
        width * this.props.widthModifier,
        width * this.props.widthModifier * (this.imageHeight / this.imageWidth)
      )
    } else {
      this.sprite.scale.set(width * this.props.widthModifier, height)
    }
    this.glitchPass.goWild = this.props.goWild
    this.props.onImageLoaded()
    // this.camera.position.y = that.pageHeight / 2 - height / 2
  }

  componentDidMount() {
    const width = this.root.clientWidth
    const height = this.root.clientHeight
    this.img = new Image()
    this.img.crossOrigin = 'anonymous'

    // Setup the scene:
    const scene = new THREE.Scene()
    this.camera = new THREE.OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      1,
      1000
    )
    this.camera.position.z = 2

    // Add elements to the scene:
    this.texture = new THREE.Texture()
    this.texture.image = this.img
    this.texture.minFilter = THREE.NearestMipMapLinearFilter
    this.texture.magFilter = THREE.LinearMipMapLinearFilter
    const spriteMaterial = new THREE.SpriteMaterial({
      map: this.texture,
      color: 0xffffff,
    })
    const sprite = new THREE.Sprite(spriteMaterial)
    sprite.center.set(0.5, 0.5)
    scene.add(sprite)
    this.sprite = sprite

    this.img.onload = this.onImageLoaded

    this.renderer = new THREE.WebGLRenderer({
      antialias: false,
      alpha: true,
    })
    this.renderer.setSize(width, height)
    this.renderer.setPixelRatio(
      this.props.forcePixelRatio || window.devicePixelRatio
    )

    // Setup composer with passes:
    this.composer = new EffectComposer(this.renderer)

    const renderPass = new RenderPass(scene, this.camera)
    this.composer.addPass(renderPass)

    this.glitchPass = new GlitchPass({
      glitchOffset: this.props.glitchOffset,
      frequencyDivider: this.props.frequencyDivider,
      minXDistortion: this.props.minXDistortion,
      maxXDistortion: this.props.maxXDistortion,
      minYDistortion: this.props.minYDistortion,
      maxYDistortion: this.props.maxYDistortion,
      glitchAmountDivider: this.props.glitchAmountDivider,
      minAngle: this.props.minAngle,
      maxAngle: this.props.maxAngle,
      showNoise: this.props.showNoise,
      applyGlitch: this.props.applyGlitch,
    })

    this.glitchPass.renderToScreen = true
    this.glitchPass.goWild = true
    this.composer.addPass(this.glitchPass)

    // Add the renderer to the DOM:
    this.root.appendChild(this.renderer.domElement)
    window.addEventListener('resize', this.onWidowResize, false)

    this.timeout = window.setTimeout(() => {
      this.start()
    }, 1000)

    this.getImage()
  }

  componentWillUnmount() {
    this.stop()
    this.root.removeChild(this.renderer.domElement)
    window.clearTimeout(this.timeout)
    window.removeEventListener('resize', this.onWidowResize)
  }

  renderScene() {
    const dimensions = this.root.getBoundingClientRect()
    const yOffset = dimensions.y + dimensions.height
    if (
      this.props.glitchAnyways ||
      (yOffset > 0 && this.root.offsetParent !== null)
    ) {
      if (this.props.show) {
        this.composer.render()
      }
    }
  }

  animate() {
    this.renderScene()
    this.frameId = window.requestAnimationFrame(this.animate)
  }

  start() {
    if (!this.frameId) {
      this.frameId = window.requestAnimationFrame(this.animate)
    }
  }

  stop() {
    window.cancelAnimationFrame(this.frameId)
  }

  onWidowResize() {
    const width = this.root.clientWidth
    const height = this.root.clientHeight

    if (width > 0 && height > 0) {
      if (this.props.maintainAspectRatio) {
        this.sprite.scale.set(
          width * this.props.widthModifier,
          width *
            this.props.widthModifier *
            (this.imageHeight / this.imageWidth)
        )
      } else {
        this.sprite.scale.set(width * this.props.widthModifier, height)
      }
      this.camera.left = width / -2
      this.camera.right = width / 2
      this.camera.top = height / 2
      this.camera.bottom = height / -2
      this.camera.updateProjectionMatrix()
      this.renderer.setSize(width, height)
    }
  }

  render() {
    const { show, style } = this.props
    const { hide } = this.state
    return (
      <div
        style={{
          width: '100%',
          height: hide ? '0' : '100%',
          position: 'absolute',
          top: show ? 0 : '100%',
          zIndex: show ? 50 : -1,
          display: hide ? 'none' : 'block',
          ...style,
        }}
        ref={ref => (this.root = ref)}
      />
    )
  }
}
